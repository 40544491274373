/* eslint-disable react/jsx-no-useless-fragment */
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import auth0PermissionsAtom, {
  isPermissionLoaded,
} from "../../recoil/auth0permissions.ts/permissionsAtom";
import { Auth0Permission } from "../../../types/permissions";
import useFetchUserPermissions from "../../hooks/useFetchUserPermissions";

interface ProtectedRouteWrapperProps {
  children: React.ReactElement | React.ReactElement[];
  requiredPermissions: Auth0Permission[];
}

export default function ProtectedRouteWrapper({
  children,
  requiredPermissions,
}: ProtectedRouteWrapperProps) {
  // Read permissions from state
  useFetchUserPermissions();
  const permissions = useRecoilValue(auth0PermissionsAtom);
  const permissionsLoaded = useRecoilValue(isPermissionLoaded);

  const hasRequiredPermissions = requiredPermissions.some((permission) =>
    permissions.includes(permission),
  );

  if (!hasRequiredPermissions && permissionsLoaded) {
    return <Navigate to="/403" />;
  }

  return <>{children}</>;
}
