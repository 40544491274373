import { Auth0Role, RoleConfig } from "../../types/roles";

// All roles configured in Auth0
const {
  admin,
  billingAdmin,
  coc00nAdmin,
  partnerAdmin,
  userAdmin,
  protectedUser,
  partnerUser,
  coc00nUser,
}: Record<string, Auth0Role> = {
  admin: "ROLE:ADMIN",
  billingAdmin: "ROLE:BILLING_ADMIN",
  coc00nAdmin: "ROLE:COC00N_ADMIN",
  partnerAdmin: "ROLE:PARTNER_ADMIN",
  userAdmin: "ROLE:USER_ADMIN",
  protectedUser: "ROLE:PROTECTED_USER",
  partnerUser: "ROLE:PARTNER_USER",
  coc00nUser: "ROLE:COC00N_READER",
};

// Role config - determines which options are shown when inviting a new user or assigning roles
const rolesConfig: RoleConfig[] = [
  {
    role: admin,
    displayName: "Administrator",
    description: "Organisation Administrator",
    rolesRequiredToAssign: [admin, coc00nAdmin],
  },
  {
    role: billingAdmin,
    displayName: "Billing Administrator",
    description: "Administrator with access and control over billing details",
    rolesRequiredToAssign: [admin, coc00nAdmin],
  },
  {
    role: coc00nAdmin,
    displayName: "Coc00n Administrator",
    description: "Coc00n Administrator",
    rolesRequiredToAssign: [coc00nAdmin],
  },
  {
    role: coc00nUser,
    displayName: "Coc00n Reader",
    description: "Coc00n Reader",
    rolesRequiredToAssign: [coc00nAdmin],
  },
  {
    role: partnerAdmin,
    displayName: "Partner Administrator",
    description: "Partner Administrator",
    rolesRequiredToAssign: [coc00nAdmin],
  },
  {
    role: partnerUser,
    displayName: "Partner Reader",
    description: "Partner Reader",
    rolesRequiredToAssign: [coc00nAdmin, partnerAdmin],
  },
  {
    role: protectedUser,
    displayName: "Protected User",
    description: "A user protected by Coc00n's services",
    rolesRequiredToAssign: [userAdmin, admin, coc00nAdmin],
  },
  {
    role: userAdmin,
    displayName: "User Administrator",
    description: "A user administrator",
    rolesRequiredToAssign: [userAdmin, admin, coc00nAdmin],
  },
];

export const auth0Roles = {
  admin,
  billingAdmin,
  coc00nAdmin,
  coc00nUser,
  partnerAdmin,
  partnerUser,
  protectedUser,
  userAdmin,
};

export default rolesConfig;
