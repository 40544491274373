import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import auth0PermissionsAtom from "../../recoil/auth0permissions.ts/permissionsAtom";
import permissionsMap from "../../constants/permissions";
import SecureComponentWrapper from "./SecureComponentWrapper";

export default function Coc00nNavbar() {
  const { logout } = useAuth0();

  const [expanded, setExpanded] = useState(false);

  const expand = "md";

  // Get permissions from state
  const permissions = useRecoilValue(auth0PermissionsAtom);

  // Render valid options

  return (
    <Navbar
      key={expand}
      expand={expand}
      expanded={expanded}
      className="bg-body-tertiary mb-3"
      collapseOnSelect
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          Coc00n
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          className="w-100"
          style={{ top: 56 }}
          backdrop={false}
        >
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                Home
              </Nav.Link>
              <SecureComponentWrapper
                requiredPermissions={permissionsMap.viewAdminTools!}
              >
                <Nav.Link
                  as={Link}
                  to="/admin"
                  onClick={() => setExpanded(false)}
                >
                  Admin
                </Nav.Link>
              </SecureComponentWrapper>
            </Nav>
            <Button
              variant="secondary"
              onClick={() => console.log(permissions)}
            >
              Print permissions to log
            </Button>
            <Button
              variant="secondary"
              onClick={() => console.log(permissionsMap)}
            >
              Print permissions map to log
            </Button>
            <Button
              type="button"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Log out
            </Button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
