import { Modal, Spinner } from "react-bootstrap";

interface LoadingModalProps {
  show: boolean;
}

export default function LoadingModal({ show }: LoadingModalProps) {
  return (
    <Modal show={show}>
      <Spinner />
    </Modal>
  );
}
