/* eslint-disable react/jsx-no-useless-fragment */
// Only renders child components if user has required permissions, in contrast to ProtectedRoute, which redirects

import { useRecoilValue } from "recoil";
import { Auth0Permission } from "../../../types/permissions";
import useFetchUserPermissions from "../../hooks/useFetchUserPermissions";
import auth0PermissionsAtom, {
  isPermissionLoaded,
} from "../../recoil/auth0permissions.ts/permissionsAtom";

interface SecureComponentWrapperProps {
  children: React.ReactElement[] | React.ReactElement;
  requiredPermissions: Auth0Permission[];
}

export default function SecureComponentWrapper({
  children,
  requiredPermissions,
}: SecureComponentWrapperProps) {
  // Read permissions from state
  useFetchUserPermissions();
  const permissions = useRecoilValue(auth0PermissionsAtom);
  const permissionsLoaded = useRecoilValue(isPermissionLoaded);

  const hasRequiredPermissions = requiredPermissions.some((permission) =>
    permissions.includes(permission),
  );

  if (hasRequiredPermissions && permissionsLoaded) {
    return <>{children}</>;
  }

  return null;
}
