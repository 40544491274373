import { Suspense, lazy } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Coc00nNavbar from "./components/global/Coc00nNavbar";
import Admin from "./pages/Admin";
import useFetchUserPermissions from "./hooks/useFetchUserPermissions";
import Unauthorised from "./pages/errors/Unauthorised";
import NotFound from "./pages/errors/NotFound";
import permissionsMap from "./constants/permissions";
import ProtectedRouteWrapper from "./components/global/ProtectedRouteWrapper";

const Home = lazy(() => import("./pages/Home"));

export default function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  const query = new URLSearchParams(document.location.search);

  const invitation = query.get("invitation");
  const organization = query.get("organization");

  useFetchUserPermissions();

  if (isLoading) {
    return <Spinner />;
  }

  // If error is due to not having organisation, we will want to handle this differently if we allow public sign ups

  if (
    error &&
    error.message ===
      "client requires organization membership, but user does not belong to any organization"
  ) {
    return (
      <div>
        You do not currently belong to any organisations, please create a coc00n
      </div>
    );
  }

  if (error) {
    return (
      <div>
        Oops...
        {error.message}
      </div>
    );
  }

  if (!isAuthenticated && invitation && organization && !error) {
    loginWithRedirect({
      authorizationParams: {
        invitation,
        organization,
      },
    });
  } else if (!isAuthenticated && !error) {
    loginWithRedirect();
  }

  return (
    <BrowserRouter>
      <Suspense>
        <Coc00nNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/admin"
            element={
              <ProtectedRouteWrapper
                requiredPermissions={permissionsMap.viewAdminTools!}
              >
                <Admin />
              </ProtectedRouteWrapper>
            }
          />
          {/* Error routes */}
          <Route path="/403" element={<Unauthorised />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
