import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import auth0PermissionsAtom from "../../recoil/auth0permissions.ts/permissionsAtom";
import rolesConfig, { auth0Roles } from "../../constants/roles";
import RolesDropdown from "./RolesDropdown";
import LoadingModal from "../global/LoadingOverlay";
import { Auth0Role } from "../../../types/roles";

interface CreateCoc00nFormData {
  orgName: string;
  ownerName: string;
  ownerEmail: string;
  ownerRoles: Auth0Role[];
}

interface CreateCoc00nFormProps {
  onCancel?: () => void;
}

export default function CreateCoc00nForm(
  { onCancel }: CreateCoc00nFormProps = { onCancel: undefined },
) {
  // This will be removed when api is plugged in, as we can use loading states from react query, just for testing
  const [isLoading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<CreateCoc00nFormData>({
    defaultValues: {
      orgName: "",
      ownerName: "",
      ownerEmail: "",
      ownerRoles: [auth0Roles.admin, auth0Roles.protectedUser],
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onSubmit = (data: CreateCoc00nFormData) => {
    console.log("Creating Coc00n! ", data);

    setLoading(true);
    setTimeout(() => {
      reset();
      setLoading(false);
    }, 1000);
  };

  // Get permissions from state for filtering roles that calling user can assign
  const permissions = useRecoilValue(auth0PermissionsAtom);

  const availableRoles = rolesConfig.filter((role) =>
    role.rolesRequiredToAssign.some(
      (allowedRole) => permissions && permissions.includes(allowedRole),
    ),
  );

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column justify-content-center p-2"
    >
      <LoadingModal show={isLoading} />
      <h4 className="text-center">Create new Coc00n</h4>
      <Form.Group controlId="orgName">
        <Form.Label>Organisation Name</Form.Label>
        <Controller
          name="orgName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="text" {...field} />}
        />
      </Form.Group>
      <Form.Group controlId="ownerName">
        <Form.Label>Owner Name</Form.Label>
        <Controller
          name="ownerName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="text" {...field} />}
        />
      </Form.Group>
      <Form.Group controlId="ownerEmail">
        <Form.Label>Owner Email</Form.Label>
        <Controller
          name="ownerEmail"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Form.Control type="email" {...field} />}
        />
      </Form.Group>
      <Form.Group controlId="ownerRoles">
        <Form.Label>Owner Roles</Form.Label>
        <Controller
          name="ownerRoles"
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <RolesDropdown
              availableRoles={availableRoles}
              assignedRoles={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Form.Group>
      <div className="d-flex flex-column gap-1 my-2">
        <Button type="submit" variant="primary" className="rounded">
          Submit
        </Button>
        {onCancel && (
          <Button onClick={onCancel} variant="secondary" className="rounded">
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
}
