import { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import CreateCoc00nForm from "../components/forms/CreateCoc00n";
import InviteUser from "../components/forms/InviteUser";
import SecureComponentWrapper from "../components/global/SecureComponentWrapper";

export default function Admin() {
  // Possible admin functions
  const [showCreateCoc00nModal, setShowCreateCoc00nModal] = useState(false);

  return (
    <Container>
      <SecureComponentWrapper requiredPermissions={["create:Coc00nBasic_all"]}>
        <Modal
          show={showCreateCoc00nModal}
          onHide={() => setShowCreateCoc00nModal(false)}
        >
          <CreateCoc00nForm onCancel={() => setShowCreateCoc00nModal(false)} />
        </Modal>
        <Button onClick={() => setShowCreateCoc00nModal(true)} className="m-2">
          Create Coc00n
        </Button>
      </SecureComponentWrapper>
    </Container>
  );
}
