import { useMemo } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Container,
  Dropdown,
} from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { Auth0Role, RoleConfig } from "../../../types/roles";
import rolesConfig from "../../constants/roles";

export default function RolesDropdown({
  availableRoles,
  assignedRoles,
  onChange,
}: {
  availableRoles: RoleConfig[];
  assignedRoles: Auth0Role[];
  onChange: (roleArr: Auth0Role[]) => void;
}) {
  const handleAddRole = (roleToAdd: Auth0Role) => {
    onChange([roleToAdd, ...assignedRoles]);
  };

  const handleRemoveRole = (roleToRemove: Auth0Role) => {
    onChange(assignedRoles.filter((role) => role !== roleToRemove));
  };

  const unassignedRoles = useMemo(
    () =>
      availableRoles.filter(
        (roleConfig) => !assignedRoles.includes(roleConfig.role),
      ),
    [availableRoles, assignedRoles],
  );

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle variant="outline" disabled={!unassignedRoles.length}>
          Assign Roles
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {unassignedRoles.map((roleConfig) => (
            <Dropdown.Item
              key={roleConfig.role}
              onClick={() => handleAddRole(roleConfig.role)}
              active={assignedRoles.includes(roleConfig.role)}
            >
              {roleConfig.displayName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Container className="d-flex flex-wrap mt-1 p-0 gap-2">
        {assignedRoles.map((role) => (
          <Badge
            key={role}
            bg="secondary"
            className="rounded py-1 px-2 d-flex align-items-center"
          >
            {rolesConfig.find((config) => config.role === role)?.displayName}
            <Button
              variant="secondary"
              className="p-0 m-0 ms-1"
              onClick={() => handleRemoveRole(role)}
            >
              <X className="bg-secondary" />
            </Button>
          </Badge>
        ))}
      </Container>
    </>
  );
}
