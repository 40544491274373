/*
  {
    thingUserCanDo : [listOfPermissionsThatAllowThisAction]
  }
*/

import { Auth0Permission, PermissionsAction } from "../../types/permissions";

const coc00nAdminToolsActionsMap: Partial<
  Record<PermissionsAction, Auth0Permission[]>
> = {
  createCoc00n: ["create:Coc00nBasic_all"],
  updateCoc00n: ["update:Coc00nBasic_all"],
};

const standardActionsMap: Partial<
  Record<PermissionsAction, Auth0Permission[]>
> = {};

const permissionsMap: Partial<Record<PermissionsAction, Auth0Permission[]>> = {
  ...coc00nAdminToolsActionsMap,
  ...standardActionsMap,
};

// If any admin tools available,
const viewAdminTools = {
  viewAdminTools: [...new Set(Object.values(permissionsMap))].flat(),
};

Object.assign(permissionsMap, viewAdminTools);

export default permissionsMap;
